<template>
    <form id="sbLoungeForm" class="form" @submit.prevent="submitForm()">
        <div class="airport-terminal">
            <SearchboxComponentsSimpleSelect
                name="airport"
                :title="destinationTitle"
                :tooltip="destinationTooltip"
                :placeholder="destinationPlaceholder"
                :options="airportList"
                :value="formData.destination.value"
                :validation="v$.destination?.code"
                @update:value="(value: any) => updateDestination(value)"
            />
            <SearchboxComponentsSimpleSelect
                v-if="terminals.length > 0"
                name="terminal"
                title=""
                tooltip=""
                placeholder="Terminal"
                :options="terminals"
                :value="formData.terminal"
                :validation="v$.terminal"
                @update:value="(value: any) => updateTerminal(value)"
            />
        </div>
        <div class="input-group-wrapper">
            <SearchboxComponentsDateTimePicker
                :label="{ date: 'Entry', time: 'Time' }"
                name="departureDate"
                :min-date="new Date()"
                :min-time="minTime"
                :value="formData.dates?.start"
                :validation="v$?.dates?.start"
                :allow-single-range="true"
                @update:value="(value: any) => updateEntryDate(value)"
            />

            <div class="input-group-container error-container">
                <div v-if="v$.dates?.start?.time && v$.dates?.start?.time.$errors.length" class="error-tooltip">
                    {{ v$.dates?.start?.time.$errors[0].$message }}
                </div>
            </div>
        </div>
        <div class="input-group-container flex">
            <SearchboxComponentsGuests
                name="guests"
                title="Guests"
                tooltip=""
                :value="formData.guests"
                :validation="v$.guests"
                prepend-icon="arrow-down-s-line"
                @update:value="(value: any) => updateGuests(value)"
            />
        </div>
        <div v-if="showPromo" class="input-group-container flex">
            <SearchboxComponentsSimpleInput
                title="Discount Code"
                placeholder="Enter Code"
                name="promocode"
                :value="formData.promo"
                class="!p-0"
                @update:value="(value: any) => updatePromo(value)"
            />
        </div>
        <div class="input-group-container flex">
            <div class="submit">
                <button type="submit">
                    <template v-if="!loading">Search</template>
                    <NuxtIcon v-show="loading" name="ri:refresh-line" class="animate-spin loading-icon" />
                </button>
            </div>
        </div>

        <SearchboxComponentsExtras
            v-if="tabs.Extras && tabs.keySellingPoints?.length"
            :key-selling-points="tabs.keySellingPoints"
            class="flex"
        />
    </form>
</template>
<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { useSearchStore } from '../../../../../apps/_base/store/search';
import { formatDate } from '~ui/utils/helpers';
import { getDateDaysAhead } from '~ui/utils/helpers';

import type { ISearchboxTab } from '../../../../../packages/ui/types';
import type { IAirportLounge } from '~/types/search';

import {
    isMigrationActive,
    checkMigrationVariant,
    inMigratedVariant,
    redirectToLoungePropel,
} from '~/mixins/traffic-splitter';
import { differenceInDaysWithoutTime, nextTimeSlot } from '~ui/utils/dateTimeHelper';

const { loading, airportList, defaultLocation, defaultPromo } = defineProps<{
    tabs: ISearchboxTab;
    airportList: [any];
    defaultLocation: string;
    loading: boolean;
    defaultPromo: string;
    showPromo: boolean;
}>();
const emit = defineEmits<{
    (e: 'submitSearch', query: IAirportLounge): void;
    (e: 'toggleLoading'): void;
}>();

// Constant variable for access to search store
const searchStore: any = useSearchStore();
const route: any = useRoute();
const minTime = ref('');
const terminals = computed(() => {
    return airportList.find((airport) => airport.code === formData.value.destination?.code)?.terminals || [];
});

const destinationTitle = computed(() => {
    if (searchStore.isCruiseport) {
        return 'Cruise Port';
    }

    if (terminals.value?.length) {
        return 'Airport and Terminal';
    }

    return 'Airport';
});

const destinationTooltip = computed(() => {
    return `Which ${searchStore.isCruiseport ? 'harbour' : 'airport'} are you departing from?`;
});

const destinationPlaceholder = computed(() => {
    return `Enter ${searchStore.isCruiseport ? 'Cruise Port' : 'Airport'}`;
});

const formData: any = ref({
    destination: getDefaultAirport(),
    terminal: searchStore.destination?.terminal || '',
    dates: searchStore.dates?.start?.date
        ? {
              start: {
                  date: new Date(searchStore.dates?.start?.date),
                  time: searchStore.dates?.start?.time?.value || searchStore.dates?.start?.time,
              },
              end: {
                  date: new Date(searchStore.dates?.end?.date),
                  time: searchStore.dates?.end?.time?.value || searchStore.dates?.end?.time,
              },
          }
        : {
              start: {
                  date: getDateDaysAhead(new Date(), 1),
                  time: '12:00',
                  timeRange: { value: '12:00 - 13:00', label: '12:00 - 13:00' },
              },
              end: { date: getDateDaysAhead(new Date(), 8), time: '12:00' },
          },
    guests: {
        adults: searchStore.guests?.adults || 1,
        children: searchStore.guests?.children || 1,
    },
    promo: route.query.promo || searchStore.promo || defaultPromo || '',
});

onMounted(() => {
    if (isMigrationActive) {
        checkMigrationVariant(searchStore.destination?.code, searchStore.dates.end?.date, '_airportlounge');
    }
});

watch(
    () => formData.value,
    async () => {
        if (isMigrationActive) {
            await checkMigrationVariant(
                formData.value.destination?.code,
                formData.value.dates?.end?.date,
                '_airportlounge'
            );
        }
    },
    { deep: true }
);

function getDefaultAirport() {
    if (defaultLocation) {
        return airportList.find((airport) => airport.code === defaultLocation);
    }

    if (searchStore.destination?.code) {
        return searchStore.destination;
    }

    return { code: '', label: 'Select Airport', value: 0, disabled: false };
}

const updateDestination = (val: any) => {
    formData.value.destination = val;
    formData.value.terminal = '';
    searchStore.update({
        destination: {
            ...formData.value.destination,
            terminal: formData.value.terminal,
        },
    });
    if (isMigrationActive) {
        checkMigrationVariant(searchStore.destination?.code, searchStore.dates.end?.date, '_airportlounge');
    }
};

const updateEntryDate = (val: any) => {
    formData.value.dates.start.date = val.date;
    formData.value.dates.start.time = val.time;

    if (differenceInDaysWithoutTime(new Date(), new Date(formData.value.dates.start.date)) <= 0) {
        const now = new Date();
        const hour = (now.getHours() < 10 ? '0' : '') + now.getHours();
        const min = (now.getMinutes() < 10 ? '0' : '') + now.getMinutes();
        minTime.value = `${hour}:${min}`;
        formData.value.dates.start.time = nextTimeSlot(minTime.value, 60);
    }

    searchStore.update({
        dates: formData.value.dates,
    });
};

const updateGuests = (val: any) => {
    formData.value.guests = val;

    searchStore.update({
        guests: {
            ...searchStore.guests,
            adults: formData.value.guests.adults,
            children: formData.value.guests.children,
        },
    });
};

const updateTerminal = (val: any) => {
    formData.value.terminal = val.value;
    searchStore.update({
        destination: {
            ...formData.value.destination,
            terminal: formData.value.terminal,
        },
    });
};

const updatePromo = (val: any) => {
    searchStore.updatePromo(val);
};

watch(
    () => searchStore.promo,
    (promo) => {
        formData.value.promo = promo;
    }
);

const validate24hour = () => {
    const startDate = new Date(formData.value.dates.start.date);
    const startTime = formData.value.dates.start.time.split(':');
    startDate.setHours(startTime[0]);
    startDate.setMinutes(startTime[1]);

    const currentTime = new Date();
    const twentyFourHoursFromNow = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);
    return startDate > twentyFourHoursFromNow;
};

const rules = computed(() => {
    return {
        destination: {
            code: {
                required: helpers.withMessage('Please enter destination', required),
                minLength: minLength(3),
            },
        },
        dates: {
            start: {
                date: {
                    required: helpers.withMessage('Select Date', required),
                    validate24hour: validate24hour,
                },
                time: {
                    required: helpers.withMessage('Select Time', required),
                    validate24hour: helpers.withMessage(
                        'You must select a date and time 24 hours after the current time',
                        validate24hour
                    ),
                },
            },
        },
    };
});

const v$: any = useVuelidate(rules, formData);

const submitForm = () => {
    v$.value.$validate();

    if (!v$.value.$error) {
        emit('toggleLoading');

        searchStore.update({
            destination: {
                ...formData.value.destination,
                terminal: formData.value.terminal,
            },
            dates: formData.value.dates,
            guests: {
                ...searchStore.guests,
                adults: formData.value.guests.adults,
                children: formData.value.guests.children,
            },
        });
        searchStore.updatePromo(formData.value.promo);

        if (isMigrationActive && inMigratedVariant.value) {
            redirectToLoungePropel();
            return;
        }

        const query: IAirportLounge = {
            category: 'airportlounge',
            airportA: Number(formData.value.destination.value),
            airportA_code: formData.value.destination.code,
            dateA: formatDate(formData.value.dates.start.date, 'yyyy-mm-dd'),
            dateB: formatDate(formData.value.dates.start.date, 'yyyy-mm-dd'),
            _dateA: formatDate(formData.value.dates.start.date, 'day, dd/mm/yy'),
            _dateB: formatDate(formData.value.dates.start.date, 'day, dd/mm/yy'),
            timeA: formData.value.dates.start.time,
            timeB: formData.value.dates.start.time,
            adults: formData.value.guests?.adults,
            children: formData.value.guests?.children,
            infants: formData.value.guests?.infants || 0,
            passengers: 1,
            promo: formData.value.promo,
            agent: 'Lounges',
            params: [],
            terminal: formData.value.terminal,
            quoteID: '',
        };

        emit('submitSearch', query);
    }
};
</script>
