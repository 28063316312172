<template>
    <form id="sbParkingForm" class="form" @submit.prevent="submitForm()">
        <div class="airport-terminal">
            <SearchboxComponentsSimpleSelect
                name="airport"
                :title="destinationTitle"
                :tooltip="destinationTooltip"
                :placeholder="destinationPlaceholder"
                :options="airportList"
                :value="formData.destination.value"
                :validation="v$.destination?.code"
                @update:value="(value: any) => updateDestination(value)"
            />
            <SearchboxComponentsSimpleSelect
                v-if="terminals?.length"
                name="terminal"
                title=""
                tooltip=""
                placeholder="Terminal"
                :options="terminals"
                :value="formData.terminal"
                :validation="v$.terminal"
                @update:value="(value: any) => updateTerminal(value)"
            />
        </div>
        <SearchboxComponentsDateTimePicker
            :package-info="option"
            :label="{ date: 'Entry', time: 'Time' }"
            name="dropoff"
            :min-date="new Date()"
            :min-time="minEntryTime"
            :value="formData?.dates?.start"
            :validation="v$?.dates?.start"
            :allow-single-range="true"
            @update:value="(value: any) => updateEntryDate(value)"
        />
        <SearchboxComponentsDateTimePicker
            :package-info="option"
            :label="{ date: 'Exit', time: 'Time' }"
            name="pickup"
            :min-date="formData.dates.start?.date ? new Date(formData.dates.start?.date) : new Date()"
            :min-time="minToTime"
            :value="formData.dates.end"
            :validation="v$?.dates?.end"
            :allow-single-range="true"
            @update:value="(value: any) => updateExitDates(value)"
        />
        <div class="input-group-container flex">
            <SearchboxComponentsSimpleInput
                v-if="showPromo"
                title="Discount Code"
                placeholder="Enter Code"
                name="promocode"
                :value="formData.promo"
                class="flex-1 !p-0"
                @update:value="(value: any) => updatePromo(value)"
            />
            <div class="submit flex-1">
                <button type="submit">
                    <template v-if="!loading">Search</template>
                    <NuxtIcon v-show="loading" name="ri:refresh-line" class="animate-spin loading-icon" />
                </button>
            </div>
        </div>
        <SearchboxComponentsExtras
            v-if="tabs.Extras && tabs.keySellingPoints?.length"
            :key-selling-points="tabs.keySellingPoints"
            class="flex"
        />
    </form>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import type { Validation } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { useSearchStore } from '@/../_base/store/search';
import { differenceInDays, formatDate } from '~ui/utils/helpers';
import type { ISearchboxTab } from '~ui/types';
import type { IAirportParking } from '@/types/search';

import {
    isMigrationActive,
    checkMigrationVariant,
    inMigratedVariant,
    redirectToParkingPropel,
} from '~/mixins/traffic-splitter';
import { differenceInDaysWithoutTime, isEndDateBeforeStartDate, nextTimeSlot } from '~ui/utils/dateTimeHelper';

const { loading, defaultPromo, defaultLocation, airportList, showPromo } = defineProps<{
    tabs: ISearchboxTab;
    airportList: [any];
    defaultPromo: string;
    defaultLocation: string;
    showPromo: boolean;
    loading: boolean;
}>();

const emit = defineEmits<{
    (e: 'submitSearch', query: IAirportParking): void;
    (e: 'toggleLoading'): void;
}>();

// Constant variable for access to search store
const searchStore: any = useSearchStore();
const route: any = useRoute();
const terminals = ref([]);

const destinationTitle = computed(() => {
    if (searchStore.isCruiseport) {
        return 'Cruise Port';
    }

    if (terminals.value?.length) {
        return 'Airport and Terminal';
    }

    return 'Airport';
});

const destinationTooltip = computed(() => {
    return `Which ${searchStore.isCruiseport ? 'harbour' : 'airport'} are you departing from?`;
});

const destinationPlaceholder = computed(() => {
    return `Enter ${searchStore.isCruiseport ? 'Cruise Port' : 'Airport'}`;
});

// Define form data type with more flexible date handling
interface FormData {
    destination: any;
    dates: {
        start: {
            date: Date;
            time: string;
            timeRange?: { value: string; label?: string };
        };
        end: {
            date: Date;
            time: string;
        };
    };
    promo: string;
    guests?: {
        rooms?: {
            label: string;
            value: number;
        };
        adult?: number;
        children?: number;
        infants?: number;
    };
}

const updateDestination = async (val: any) => {
    formData.value.destination = val;
    terminals.value = val.terminals || [];
    formData.value.terminal = '';
    searchStore.update({
        destination: {
            ...formData.value.destination,
            terminal: formData.value.terminal,
        },
    });

    if (isMigrationActive()) {
        await checkMigrationVariant(formData.value.destination?.code, formData.value.dates?.end?.date);
    }
};

const updateDates = async (val: any, position: string) => {
    formData.value.dates[position].date = val.date;
    formData.value.dates[position].time = val.time;
    searchStore.update({ dates: formData.value.dates });
    if (isMigrationActive()) {
        await checkMigrationVariant(formData.value.destination?.code, formData.value.dates?.end?.date);
    }
};

const updatePromo = (val: any) => {
    searchStore.updatePromo(val);
};

watch(
    () => searchStore.promo,
    (promo) => {
        formData.value.promo = promo;
    }
);

if (!searchStore.promo && defaultPromo) {
    searchStore.updatePromo(defaultPromo);
}

const formData = ref<FormData>({
    destination: getDefaultAirport(),
    terminal: searchStore.destination?.terminal || '',
    dates: searchStore.dates?.start?.date
        ? searchStore.dates
        : {
              start: {
                  date: getDateDaysAhead(new Date(), 1),
                  time: '12:00',
                  timeRange: { value: '12:00 - 13:00', label: '12:00 - 13:00' },
              },
              end: { date: getDateDaysAhead(new Date(), 8), time: '12:00' },
          },
    promo: route.query.promo || searchStore.promo || defaultPromo || '',
});

function getDefaultAirport() {
    if (defaultLocation) {
        const airport = airportList.find((airport) => airport.code === defaultLocation);
        terminals.value = airport.terminals || [];
        return airport;
    }

    if (searchStore.destination?.code) {
        terminals.value = searchStore.destination?.terminals || [];
        if (terminals.value.length === 0) {
            const airport = airportList.find((airport) => airport.code === searchStore.destination?.code);
            terminals.value = airport.terminals;
        }
        return searchStore.destination;
    }

    return { code: '', label: 'Select Airport', value: 0, disabled: false };
}

const option = computed(() => {
    return {
        id: 'APR',
        name: 'At the parkinglot',
        additional: '',
        start_date: {
            title: 'Parking Check-In',
            tooltip: 'Date when your parking begins',
            placeholder: 'Select Date',
        },
        end_date: {
            title: 'Parking Check-Out',
            tooltip: 'Date when your parking ends',
            placeholder: 'Select Date',
        },
    };
});

const beforeCurrentTime = () => {
    const currentTime = new Date();
    const startDate = new Date(formData.value.dates.start.date);
    const startTime = formData.value.dates.start.time.split(':');

    startDate.setHours(parseInt(startTime[0]));
    startDate.setMinutes(parseInt(startTime[1]));

    return startDate > currentTime;
};

const beforeStartDate = () => {
    return isEndDateBeforeStartDate(formData.value.dates.start, formData.value.dates.end);
};

const rules = computed(() => {
    return {
        destination: {
            code: {
                required: helpers.withMessage('Please enter airport', required),
                minLength: minLength(3),
            },
        },
        dates: {
            start: {
                date: {
                    required: helpers.withMessage('Select Date', required),
                    beforeCurrentTime: helpers.withMessage(
                        'Exit Date cannot be before the current time',
                        beforeCurrentTime
                    ),
                },
                time: { required: helpers.withMessage('Select Time', required) },
            },
            end: {
                date: {
                    required: helpers.withMessage('Select Date', required),
                    beforeStartDate: helpers.withMessage('Exit Date must be after Entry Date.', beforeStartDate),
                },
                time: { required: helpers.withMessage('Select Time', required) },
            },
        },
    };
});

onMounted(() => {
    if (isMigrationActive) {
        checkMigrationVariant(searchStore.destination?.code, searchStore.dates.end?.date);
    }
});

const v$: any = useVuelidate(rules, formData);

const checkMigration = () => {
    if (isMigrationActive) {
        checkMigrationVariant(formData.value.destination?.code, formData.value.dates?.end?.date);
    }
};

const updateTerminal = (val: any) => {
    formData.value.terminal = val.value;
    searchStore.update({
        destination: {
            ...formData.value.destination,
            terminal: formData.value.terminal,
        },
    });
};

const updateEntryDate = (val: any) => {
    formData.value.dates.start.date = val.date;
    formData.value.dates.start.time = val.time;

    if (differenceInDaysWithoutTime(new Date(), new Date(formData.value.dates.start.date)) <= 0) {
        const now = new Date();
        if (formData.value.dates.start.time <= `${now.getHours()}:${now.getMinutes()}`) {
            formData.value.dates.start.time = nextTimeSlot(`${now.getHours()}:${now.getMinutes()}`, 60);
        }
    }

    if (!beforeStartDate()) {
        formData.value.dates.end.date = formData.value.dates.start.date;
        formData.value.dates.end.time = nextTimeSlot(formData.value.dates.start.time, 60);
    }

    searchStore.update({
        dates: formData.value.dates,
    });

    checkMigration();
};

const updateExitDates = (val: any) => {
    formData.value.dates.end.date = val.date;
    formData.value.dates.end.time = val.time;
    searchStore.update({
        dates: formData.value.dates,
    });

    checkMigration();
};

const minEntryTime = computed(() => {
    const minDate = new Date(new Date(formData.value.dates.start.date).toDateString());
    const today = new Date();
    if (differenceInDaysWithoutTime(today, minDate) > 0) {
        return '';
    }

    const now = new Date();
    return `${now.getHours()}:${now.getMinutes()}`;
});

const minToTime = computed(() => {
    const minDate = new Date(new Date(formData.value.dates.start.date).toDateString());
    const toDate = new Date(new Date(formData.value.dates.end.date).toDateString());
    if (differenceInDaysWithoutTime(minDate, toDate) > 0) {
        return '';
    }

    return formData.value.dates.start.time;
});

watch(
    () => formData.value,
    async () => {
        if (isMigrationActive) {
            await checkMigrationVariant(formData.value.destination?.code, formData.value.dates?.end?.date);
        }
    },
    { deep: true }
);

const submitForm = () => {
    v$.value.$validate();

    if (!v$.value.$error) {
        emit('toggleLoading');

        searchStore.update({
            destination: {
                ...formData.value.destination,
                terminal: formData.value.terminal,
            },
            dates: formData.value.dates,
            guests: formData.value.guests,
        });
        searchStore.updatePromo(formData.value.promo);

        if (isMigrationActive && inMigratedVariant.value) {
            redirectToParkingPropel();
            return;
        }

        const query = {
            category: 'airportparking',
            airportA: Number(formData.value.destination.value),
            airportA_code: formData.value.destination.code,
            dateA: formatDate(formData.value.dates.start.date, 'yyyy-mm-dd'),
            dateB: formatDate(formData.value.dates.end.date, 'yyyy-mm-dd'),
            _dateA: formatDate(formData.value.dates.start.date, 'day, dd/mm/yy'),
            _dateB: formatDate(formData.value.dates.end.date, 'day, dd/mm/yy'),
            timeA: formData.value.dates.start.time,
            timeB: formData.value.dates.end.time,
            promo: formData.value.promo,
            passengers: 1,
            agent: 'ONLINE',
            params: [],
            terminal: formData.value.terminal,
            quoteID: '',
            nohotel: 0,
            park_type: 'all',
        };

        emit('submitSearch', query);
    }
};
</script>

<style lang="postcss" scoped></style>
